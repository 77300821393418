	
	var countries = {
		
		England : ['Avon','Bedfordshire','Berkshire','Buckinghamshire','Cambridgeshire','Cheshire','Cornwall','Cumbria','Derbyshire','Devon','Dorset','Durham','East Sussex','Essex','Gloucestershire','Greater London','Greater Manchester','Hampshire','Herefordshire','Hertfordshire','Kent','Lancashire','Leicestershire','Lincolnshire','London','Merseyside','Middlesex','Norfolk','Northamptonshire','Northumberland','Nottinghamshire','Oxfordshire','Rutland','Shropshire','Somerset','Staffordshire','Suffolk','Surrey','Warwickshire','West Midlands','West Sussex','Wiltshire','Worcestershire','Yorkshire'],
		NorthernIreland : ['Antrim','Armagh','Down','Fermanagh','Londonderry','Tyrone'],
		Scotland : ['Aberdeenshire','Angus','Argyll','Ayrshire','Banffshire','Berwickshire','Bute','Caithness','Clackmannanshire','Dumfriesshire','Dunbartonshire','East Lothian','Fife','Inverness-shire','Kincardineshire','Kinrossshire~','Kirkcudbrightshire','Lanarkshire','Midlothian','Moray','Nairnshire','Orkney','Peeblesshire','Perthshire','Renfrewshire','Ross and Cromarty','Ross-shire','Roxburghshire','Selkirkshire','Shetland','Stirlingshire','Sutherland','West Lothian','Wigtownshire'],
		Wales : ['Clwyd','Dyfed','Gwent','Gwynedd','Mid Glamorgan','Powys','South Glamorgan','West Glamorgan'],
		//International : ['US'],
		USA : ['Alabama','Alaska','Arizona','Arkansas','California','Colorado','Connecticut','Delaware','District of Columbia','Federated States of Micronesia','Florida','Georgia','Guam','Hawaii','Idaho','Illinois','Indiana','Iowa','Kansas','Kentucky','Louisiana','Maine','Marshall Islands','Maryland','Massachusetts','Michigan','Minnesota','Mississippi','Missouri','Montana','Nebraska','Nevada','New Hampshire','New Jersey','New Mexico','New York','North Carolina','North Dakota','Northern Mariana Islands','Ohio','Oklahoma','Oregon','Pennsylvania','Rhode Island','South Carolina','South Dakota','Tennessee','Texas','Utah','Vermont','Virginia','Washington','West Virginia','Wisconsin','Wyoming']
		
		
	}



;
(function($, document, window, undefined) {
    'use strict'; //this is just saying how to validate your JS.  If you use a tool like JSHint you can see your mistakes - test your code quality.
    // declare any variables you want to keep in the scope - keep refering to and updating in your functions.
	

	
    // DOCUMENT READY
    $(function() {
		
		$.each(countries, function(k, v) {
			
			k = k.replace(/([A-Z])/g, ' $1').trim()
			
			$('form .country').append('<option value="'+k+'">'+k+'</option>'); 
		
	});
	
	
	  

        bindEvents();
    });

    // EVENTS WHICH RELY ON THE DOM

    function bindEvents() {
		
		$("form .country").change(function(){
			
	var country = $(this).val();
	
	$("form .country").val(country)
	
	country = country.replace(/\s/g,'');
	
	var list = countries[country];
	
	$('form .region').empty().append('<option value="" selected="selected" disabled="disabled">Select County</option>')
	
	$.each(list, function(k, v) {
		
			$('form .region').append('<option value="'+v+'">'+v+'</option>'); 
		
	});
	
	
	
	
});
		
		
		
		
		
		
		}
})(jQuery, document, window);
