;
(function($, document, window, undefined) {
    'use strict'; //this is just saying how to validate your JS.  If you use a tool like JSHint you can see your mistakes - test your code quality.
    // declare any variables you want to keep in the scope - keep refering to and updating in your functions.


    // DOCUMENT READY
    $(function() {

	var cookie = localStorage.getItem('cookie');
	
	if(!cookie){
		
		$("#cookie_consent").fadeIn();
	}
	

	
        bindEvents();
    });

    // EVENTS WHICH RELY ON THE DOM
    function bindEvents() {
		
$("#cookie_consent button").click(function(event){
   event.preventDefault();
   localStorage.setItem("cookie", 1);
   
	   $("#cookie_consent").fadeOut();
	  
});



   	
		}
})(jQuery, document, window);
