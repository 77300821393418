var color1, color2, color3 = "#999";


if ( page.club ){
	color1 = page.club.color1;
	color2 = page.club.color2;
	color3 = page.club.color3;
}



function somethingPasted(e){
	
	
var inputText = ((e.originalEvent || e).clipboardData || window.clipboardData).getData('Text');

                e.preventDefault();
        //modify paste text as plain text
        var modifiedText = inputText .replace(/\r?\n/g, '<br>');
        document.execCommand('insertHtml', false, modifiedText );
}


$('.summernote').summernote({
		tableClassName: function()
    {
        $(this).addClass('table table-bordered')
        .css('borderCollapse', 'collapse')
		.css('width', '100%');

        $(this).find('td')
        .css('border', '1px solid #888')
		.css('padding', '1%');
    },
	 callbacks: {
		 onImageUpload: function (data) {
                                data.pop();
                            },
    onPaste: function(e) {
		
		somethingPasted(e)
    }
  },
	/*onCreateLink : function(url) {
	
		var email = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i
        var phone = /^\+?[\d()-,.]+/
        var schemed = /^[a-z]+:/i
        url = url.trim();
        if (email.test(url)) {
            url = 'mailto:' + url;
        } else if (phone.test(url)) {
                    url = 'tel:' + url.replace(/[ ().\-]/g,'');
        } else if (!schemed.test(url)) {
            url = 'http://' + url;
        }
        return url;
		
       // return originalLink; // return original link 
    },*/
	disableDragAndDrop: true,
  height: 300,/*set editor height*/
  minHeight: null,/*set minimum height of editor*/
  maxHeight: null,/*set maximum height of editor*/
  codemirror: {/*codemirror options*/
    theme: 'monokai'
  },
  focus: false ,               /* set focus to editable area after initializing summernote*/
  toolbar: [
    /* [groupName, [list of button]]*/
    ['style', ['bold', 'italic', 'underline', 'clear']],
    ['fontsize', ['fontsize']],
	['fontname', 'openSans'],
	['font', ['strikethrough', 'superscript', 'subscript']],
    ['color', ['color']],
    ['para', ['ul', 'ol', 'paragraph']],
	//['insert', [/*'link', 'codeview'*/]],
	//['table', ['table']],
	['insert', ['hr']]
  ],
  /**/colors: [
      ["#000","#999",color1, color2, color3] //second line of colors
    ],
	/**/ fontSizes: ['12', '14', '15', '16', '18', '20']
 // fontNames: ['openSans']
  
});


$('.summernote_blank').summernote({
		tableClassName: function()
    {
        $(this).addClass('table table-bordered')
        .css('borderCollapse', 'collapse')
		.css('width', '100%');

        $(this).find('td')
        .css('border', '1px solid #888')
		.css('padding', '1%');
    },
	 callbacks: {
		 onImageUpload: function (data) {
                                data.pop();
                            },
    onPaste: function(e) {
		somethingPasted(e)
    }
  },
	disableDragAndDrop: true,
  height: 150,/*set editor height*/
  minHeight: null,/*set minimum height of editor*/
  maxHeight: null,/*set maximum height of editor*/
  codemirror: {/*codemirror options*/
    theme: 'monokai'
  },
  focus: false,               /* set focus to editable area after initializing summernote*/
  toolbar: [],
  /**/colors: [],
	/**/ fontSizes: []
 // fontNames: ['openSans']
  
});

$('.summernote_email').summernote({
		tableClassName: function()
    {
        $(this).addClass('table table-bordered')
        .css('borderCollapse', 'collapse')
		.css('width', '100%');

        $(this).find('td')
        .css('border', '1px solid #888')
		.css('padding', '1%');
    },
	 callbacks: {
		 onImageUpload: function (data) {
                                data.pop();
                            },
    onPaste: function(e) {
		
		somethingPasted(e)
    }
  },
	onCreateLink : function(url) {
		
        var email = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i
        var phone = /^\+?[\d()-,.]+/
        var schemed = /^[a-z]+:/i
        url = url.trim();
        if (email.test(url)) {
            url = 'mailto:' + url;
        } else if (phone.test(url)) {
                    url = 'tel:' + url.replace(/[ ().\-]/g,'');
        } else if (!schemed.test(url)) {
            url = 'http://' + url;
        }
        return url;
       // return originalLink; // return original link 
    },
	disableDragAndDrop: true,
  height: 500,/*set editor height*/
  minHeight: null,/*set minimum height of editor*/
  maxHeight: null,/*set maximum height of editor*/
  codemirror: {/*codemirror options*/
    theme: 'monokai'
  },
  focus: false ,               /* set focus to editable area after initializing summernote*/
  toolbar: [
    /* [groupName, [list of button]]*/
    ['style', ['bold', 'italic', 'underline', 'clear']],
    ['fontsize', ['fontsize']],
	['fontname', 'openSans'],
	['font', ['strikethrough', 'superscript', 'subscript']],
    ['color', ['color']],
    ['para', ['ul', 'ol', 'paragraph']],
	['insert', ['link','hr']],
	//['table', ['table']],
  ],
   /* cleaner: {
          action: 'both',
          icon: '<i class="note-icon">[Your Button]</i>',
          keepHtml: true,
          keepTagContents: ['span'], //Remove tags and keep the contents
          badTags: ['applet', 'col', 'colgroup', 'embed', 'noframes', 'noscript', 'script', 'title', 'meta', 'link', 'head'], //Remove full tags with contents
          badAttributes: ['bgcolor', 'border', 'height', 'cellpadding', 'cellspacing', 'lang', 'start', 'style', 'valign', 'width'],
          limitChars: false,
          limitDisplay: 'both',
          limitStop: false,
          notTimeOut: 850, //time before status message is hidden in miliseconds
         imagePlaceholder: 'https://via.placeholder.com/200' // URL, or relative path to file.
    },*/
  /**/colors: [
      ["#000","#999",color1, color2, color3] //second line of colors
    ],
	/**/ fontSizes: ['12', '14', '15', '16', '18', '20']
 // fontNames: ['openSans']
  
});

$('.summernote_app').summernote({
		tableClassName: function()
    {
        $(this).addClass('table table-bordered')
        .css('borderCollapse', 'collapse')
		.css('width', '100%');

        $(this).find('td')
        .css('border', '1px solid #888')
		.css('padding', '1%');
    },
	 callbacks: {
		 onImageUpload: function (data) {
                                data.pop();
                            },
    onPaste: function(e) {
		
		somethingPasted(e)
    }
  },
	onCreateLink : function(url) {
		
        var email = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i
        var phone = /^\+?[\d()-,.]+/
        var schemed = /^[a-z]+:/i
        url = url.trim();
        if (email.test(url)) {
            url = 'mailto:' + url;
        } else if (phone.test(url)) {
                    url = 'tel:' + url.replace(/[ ().\-]/g,'');
        } else if (!schemed.test(url)) {
            url = 'http://' + url;
        }
        return url;
       // return originalLink; // return original link 
    },
	disableDragAndDrop: true,
  height: 500,/*set editor height*/
  minHeight: null,/*set minimum height of editor*/
  maxHeight: null,/*set maximum height of editor*/
  codemirror: {/*codemirror options*/
    theme: 'monokai'
  },
  focus: false ,               /* set focus to editable area after initializing summernote*/
  toolbar: [
    /* [groupName, [list of button]]*/
    ['style', ['bold', 'italic', 'underline', 'clear']],
    ['fontsize', ['fontsize']],
	['fontname', 'openSans'],
	['font', ['strikethrough', 'superscript', 'subscript']],
   //['color', ['color']],
    ['para', ['ul', 'ol']],
	['insert', ['link','hr']],
	//['table', ['table']],
  ],
   /* cleaner: {
          action: 'both',
          icon: '<i class="note-icon">[Your Button]</i>',
          keepHtml: true,
          keepTagContents: ['span'], //Remove tags and keep the contents
          badTags: ['applet', 'col', 'colgroup', 'embed', 'noframes', 'noscript', 'script', 'title', 'meta', 'link', 'head'], //Remove full tags with contents
          badAttributes: ['bgcolor', 'border', 'height', 'cellpadding', 'cellspacing', 'lang', 'start', 'style', 'valign', 'width'],
          limitChars: false,
          limitDisplay: 'both',
          limitStop: false,
          notTimeOut: 850, //time before status message is hidden in miliseconds
         imagePlaceholder: 'https://via.placeholder.com/200' // URL, or relative path to file.
    },*/
  /**/colors: [
     // ["#000","#999",color1, color2, color3] //second line of colors
    ],
	/**/ fontSizes: ['12', '14', '15', '16', '18', '20']
 // fontNames: ['openSans']
  
});


$('.summernote_supplier').summernote({
	 callbacks: {
    onPaste: function(e) {
		
		somethingPasted(e)
    }
  },
	onCreateLink : function(url) {
		
        var email = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i
        var phone = /^\+?[\d()-,.]+/
        var schemed = /^[a-z]+:/i
        url = url.trim();
        if (email.test(url)) {
            url = 'mailto:' + url;
        } else if (phone.test(url)) {
                    url = 'tel:' + url.replace(/[ ().\-]/g,'');
        } else if (!schemed.test(url)) {
            url = 'http://' + url;
        }
        return url;
       // return originalLink; // return original link 
    },
	disableDragAndDrop: true,
  height: 200,/*set editor height*/
  minHeight: null,/*set minimum height of editor*/
  maxHeight: null,/*set maximum height of editor*/
  codemirror: {/*codemirror options*/
    theme: 'monokai'
  },
  focus: false ,               /* set focus to editable area after initializing summernote*/
  toolbar: [
    /* [groupName, [list of button]]*/
    ['style', ['bold', 'italic', 'underline', 'clear']],
    ['fontsize', ['fontsize']],
	['fontname', 'openSans'],
   // ['para', ['ul', 'ol', 'paragraph']],
	//['table', ['table']],
  ],
   /* cleaner: {
          action: 'both',
          icon: '<i class="note-icon">[Your Button]</i>',
          keepHtml: true,
          keepTagContents: ['span'], //Remove tags and keep the contents
          badTags: ['applet', 'col', 'colgroup', 'embed', 'noframes', 'noscript', 'script', 'title', 'meta', 'link', 'head'], //Remove full tags with contents
          badAttributes: ['bgcolor', 'border', 'height', 'cellpadding', 'cellspacing', 'lang', 'start', 'style', 'valign', 'width'],
          limitChars: false,
          limitDisplay: 'both',
          limitStop: false,
          notTimeOut: 850, //time before status message is hidden in miliseconds
         imagePlaceholder: 'https://via.placeholder.com/200' // URL, or relative path to file.
    },*/
  /**/colors: [
      ["#000","#999",color1, color2, color3] //second line of colors
    ],
	/**/ fontSizes: ['12', '14', '15', '16', '18', '20']
 // fontNames: ['openSans']
  
});
/*

$('.summernote, .summernote_email').on('summernote.paste', function(e, ne) {
	//alert('Pasting can cause styling issues. Please consider typing fresh copy.');
	 //get the text
        var inputText = ((ne.originalEvent || ne).clipboardData || window.clipboardData).getData('Text');
		
        // block default behavior
        ne.preventDefault();
        //modify paste text as plain text
        var modifiedText = inputText .replace(/\r?\n/g, '<br>');
        document.execCommand('insertHtml', false, modifiedText );
	
	
	});*/